<template>
  <v-container id="contact">
    <v-form v-model="formValid" ref="myForm">
      <v-row>
        <v-col cols="3" md="2" lg="3" class="pa-0 ma-0 hidden-sm-and-down">
        </v-col>
        <v-col cols="12" md="8" lg="6" >
          <v-row class="text-center" dense>
            <v-col cols="12" class="py-10">
              <h1>Contact Us</h1>
            </v-col>
            <v-col cols="12" v-if="!sent" class="py-6">
              <h2>
                Contact us today to find out what we can <br/>do for your business.
              </h2>  
            </v-col>
            <v-col cols="12" v-if="!sent">
              <v-text-field 
                v-model="contactMe.name"
                label="Name*" 
                outlined
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>  
            <v-col cols="12" v-if="!sent">
              <v-text-field 
                v-model="contactMe.phone"
                label="Tel/Mobile*" 
                outlined
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>  
            <v-col cols="12" v-if="!sent">
              <v-text-field 
                v-model="contactMe.email"
                label="Email*" 
                outlined
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>  
            <v-col cols="12" v-if="!sent">
              <v-textarea 
                v-model="contactMe.message"
                label="How can we help you?" 
                outlined
                counter="500"
                :rules="[rules.required, rules.maxLength(500)]"
              ></v-textarea>
            </v-col>
            <v-col cols="12" v-if="!sent">
              <v-btn color="accent" block @click="send()" :disabled="sending">Send</v-btn>
            </v-col>  
            <v-col cols="12" class="py-16" v-if="sent">
              <h2>
                Thank you for making contact.<br/>One of our team will get back to you as soon as possible.
              </h2>  
            </v-col>
            <v-col cols="12" class="pt-10 pb-8">
              <h2>Opening Hours</h2>
              <h3>Mon - Fri <span class="px-3"></span> 9am - 5pm</h3>
            </v-col>
            <v-col cols="12">
              <h3>
                Email us<br/>
                <a href="mailto:info@powermanagementit.com">info@powermanagementit.com</a>
              </h3> 
            </v-col>   
            <v-col cols="12" class="pb-10">
              <h3>
                Call us<br/>
                <a href="tel:02871140206">028 7114 0206</a>
              </h3> 
            </v-col>   
          </v-row>         
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
  import axios from 'axios'

  export default {
    data () {
      return {
        formValid: false,
        sending: false,
        sent: false,
        contactMe: {
          name: null,
          phone: null,
          email: null,
          message: null
        },
        rules: {
          maxLength(maxNum) {
            return v => (v || '').length <= maxNum || 'Max characters exceeded';
          },
          required: v => {
            const pattern = /^.+$/
            const v2 = v == null ? '' : String(v)
            return pattern.test(v2.trim()) || "This ia a required field."
          }
        }
      }
    },
    methods: {
      send() {
        this.$refs.myForm.validate()
        if (! this.formValid) {
          return
        }
        this.sending = true
        axios.post(`https://api.powermanagementit.com/tradingdata/public/contact_us`, this.contactMe)
          .then(res => this.handleSaveComplete(res))
          .catch(error => this.handleError(error))
      }
    }
  }
</script>